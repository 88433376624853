
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 分销商后台管理系统--菜单
 * @author jing
 * @date 2021/1/19 9:49
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 * @attribute distributorType 分销商类型(ALIGJ: 阿里国际分销、YMS：云卖分销商)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006630'),
      icon: '&#xe6b2;',
      path: '/distributionMarket',
      menuKey: 'distributor_distributionMarket',
      distributorType: ['ALIGJ']
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1005461'),
      icon: '&#xe618;',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1000792'),
          path: '/myInventory',
          menuKey: 'distributor_myInventory',
          distributorType: ['YMS']
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006631'),
          path: '/commodityList',
          menuKey: 'distributor_commodityList',
          distributorType: ['YMS', 'ALIGJ']
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006632'),
          path: '/generateSalesRecords',
          menuKey: 'distributor_generateSalesRecords',
          distributorType: ['YMS', 'ALIGJ']
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1000008'),
          type: 'toListing',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在角色管理中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'distributor_shelfTools',
          distributorType: ['YMS', 'ALIGJ']
        }
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006633'),
      icon: '&#xe6fe;',
      path: '/automaticRules',
      menuKey: 'distributor_automaticRules',
      distributorType: ['YMS', 'ALIGJ']
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006634'),
      icon: '&#xe604;',
      path: '/platformStoreList',
      menuKey: 'distributor_platformStoreList',
      distributorType: ['YMS', 'ALIGJ']
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1000078'),
      icon: '&#xe63a;',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006635'),
          path: '/collectionSupplier',
          menuKey: 'distributor_collectionSupplier',
          distributorType: ['YMS']
        }
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1001038'),
      icon: '&#xe668;',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1004385'),
          path: '/platformOrder',
          menuKey: 'distributor_platformOrder',
          distributorType: ['YMS', 'ALIGJ'],
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006636'),
              path: '/platformDetails',
              menuKey: 'distributor_platformDetails',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006637'),
              path: '/addTemuOrder',
              menuKey: 'distributor_addTemuOrder',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001323')}`,
          path: '/myOrder',
          menuKey: 'distributor_myOrder',
          distributorType: ['YMS'],
          children: [
            {
              name: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1004825')}`,
              path: '/myOrderDetails',
              menuKey: 'distributor_myOrderDetails',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1004847'),
              path: '/addCloudSalesOrder',
              menuKey: 'distributor_addCloudSalesOrder',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006612'),
          path: '/distributorStockList',
          menuKey: 'distributor_distributorStockList',
          distributorType: ['YMS'],
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1001365'),
              path: '/createBackupOrder',
              menuKey: 'distributor_createBackupOrder',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006613'),
              path: '/distributorStockListDetails',
              menuKey: 'distributor_distributorStockListDetails',
              distributorType: ['YMS'],
              menuHide: true // 菜单不展示
            }
          ]
        }
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1001039'),
      icon: '&#xe645;',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002081'),
          path: '/rechargeRecord',
          menuKey: 'distributor_rechargeRecord',
          distributorType: ['YMS'],
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002716'),
          path: '/financialJournal',
          menuKey: 'distributor_financialJournal',
          distributorType: ['YMS'],
        },
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006580'),
      icon: '&#xe68e;',
      children: [
        {
          name: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1002887')}`,
          path: '/logisticsSettings',
          menuKey: 'distributor_logisticsSettings',
          distributorType: ['YMS'],
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1001076'),
          path: '/ownLogistics',
          menuKey: 'distributor_ownLogistics',
          distributorType: ['YMS'],
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006582'),
          path: '/smartRule',
          menuKey: 'distributor_smartRule',
          distributorType: ['YMS'],
        }
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006638'),
      icon: '&#xe61b;',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006639'),
          path: '/roleManage',
          menuKey: 'distributor_roleManage',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006640'),
          path: '/userManage',
          menuKey: 'distributor_userManage',
          distributorType: ['YMS', 'ALIGJ'],
        },
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1000285'),
      path: '/newsList',
      menuKey: 'distributor_newsList',
      distributorType: ['YMS', 'ALIGJ'],
      menuHide: true,
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006641'),
          path: '/newsListDetails',
          menuKey: 'distributor_newsListDetails',
          distributorType: ['YMS', 'ALIGJ'],
          menuHide: true
        }
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006642'),
      icon: '&#xe627;',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1000594'),
          path: '/basicInformation',
          menuKey: 'distributor_basicInformation',
          distributorType: ['YMS', 'ALIGJ'],
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006643'),
          path: '/securityInformation',
          menuKey: 'distributor_securityInformation',
          distributorType: ['YMS', 'ALIGJ'],
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1004346'),
              path: '/modifyMobile',
              menuKey: 'distributor_modifyMobile',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1004348'),
              path: '/modifyMailbox',
              menuKey: 'distributor_modifyMailbox',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1004589'),
              path: '/modifyPassword',
              menuKey: 'distributor_modifyPassword',
              distributorType: ['YMS', 'ALIGJ'],
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1004289'),
          path: '/iossManageList',
          menuKey: 'distributor_iossManageList',
          distributorType: ['YMS'],
        },
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006607'),
      icon: '&#xe6a9;',
      type: 'importTask',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006609'),
          path: '/exportTask',
          menuKey: 'distributor_exportTask',
          distributorType: ['YMS', 'ALIGJ'],
        }
      ]
    },
    {
      name: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1006629')}`,
      type: 'ymsShopping',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006644'),
          menuKey: 'shop_shopList'
        }
      ]
    }
  ]
};
