
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 菜单
 * @author gan
 * @date: 2020/12/17 11:09
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1005158'),
      icon: '&#xe67b;',
      path: '/statistics',
      menuKey: 'yms_statistics',
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1005461'),
      icon: '&#xe749;',
      type: 'productList',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002074'),
          path: '/productList',
          menuKey: 'yms_productList',
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1002509'),
              path: '/viewSku',
              menuKey: 'yms_viewSku',
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006550'),
              path: '/productDetails',
              menuKey: 'yms_productDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002655'),
          path: '/category',
          menuKey: 'yms_category',
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006551'),
          path: '/sizeTemplateManage',
          menuKey: 'yms_sizeTemplateManage',
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006552'),
              path: '/updateSizeTemplate',
              menuKey: 'yms_updateSizeTemplate',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006553'),
          path: '/approvalList',
          menuKey: 'yms_approvalList',
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006554'),
              path: '/approvalDetails',
              menuKey: 'yms_approvalDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1002512'),
              path: '/findSimilarProducts',
              menuKey: 'yms_findSimilarProducts',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002085'),
          path: '/itemAttributes',
          menuKey: 'yms_itemAttributes'
        },
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1001038'),
      icon: '&#xe665;',
      type: 'orderList',
      children: [
        {
          name: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1001323')}`,
          path: '/orderList',
          menuKey: 'yms_orderList',
          children: [
            {
              name: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1004825')}`,
              path: '/orderListDetails',
              menuKey: 'yms_orderListDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006555'),
              path: '/supplierOrderDetails',
              menuKey: 'yms_supplierOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006556'),
          path: '/distributorStockList',
          menuKey: 'yms_distributorStockList'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006557'),
          path: '/supplierStockList',
          menuKey: 'yms_supplierStockList',
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006558'),
              path: '/supplierStockOrderDetails',
              menuKey: 'yms_supplierStockOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002084'),
          path: '/supplierOrders',
          menuKey: 'yms_supplierOrders'
        },
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1000018'),
      icon: '&#xe679;',
      type: 'wms',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002076'),
          path: '/wms/inventoryLevels',
          menuKey: 'yms_wms_inventoryLevels'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006559'),
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006560'),
              path: '/billLadingManage',
              menuKey: 'yms_billLadingManage',
              children: [
                {
                  name: alias2f085dd487c1465383484787b29fef2c.t('key1006561'),
                  path: '/billLadingManageDetails',
                  menuKey: 'yms_billLadingManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1001814'),
              path: '/billWarehousing',
              menuKey: 'yms_billWarehousing'
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1001815'),
              path: '/commoditySorting',
              menuKey: 'yms_commoditySorting'
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1002075'),
              path: '/commoditySticker',
              menuKey: 'yms_commoditySticker'
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1001818'),
              path: '/commodityShelves',
              menuKey: 'yms_commodityShelves'
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1005654'),
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1002420'),
              path: '/assembleGeneratePickingList',
              menuKey: 'yms_assembleGeneratePickingList'
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006562'),
              path: '/assembleGeneratePickingDetails',
              menuKey: 'yms_assembleGeneratePickingDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006555'),
              path: '/assembleSupplierOrderDetails',
              menuKey: 'yms_assembleSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006563'),
              path: '/assemblePickingList',
              menuKey: 'yms_assemblePickingList'
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1002385'),
              path: '/assemblePickingListDetails',
              menuKey: 'yms_assemblePickingListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1001224'),
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1002420'),
              path: '/wms/generateOrderList',
              menuKey: 'yms_wms_generateOrderList'
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006562'),
              path: '/generateOrderDetails',
              menuKey: 'yms_generateOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006555'),
              path: '/generateSupplierOrderDetails',
              menuKey: 'yms_generateSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006563'),
              path: '/wms/pickList',
              menuKey: 'yms_wms_pickList'
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1002385'),
              path: '/wms/pickListDetails',
              menuKey: 'yms_wms_pickListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1005754'),
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006564'),
              path: '/wms/sortOrderSetting',
              menuKey: 'yms_wms_sortOrderSetting'
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1001819'),
              path: '/wms/sorting',
              menuKey: 'yms_wms_sorting'
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1001821'),
              path: '/wms/packWorking',
              menuKey: 'yms_wms_packWorking'
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1001822'),
              path: '/sortingOut',
              menuKey: 'yms_sortingOut'
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006565'),
              path: '/handoverSheetManage',
              menuKey: 'yms_handoverSheetManage',
              children: [
                {
                  name: alias2f085dd487c1465383484787b29fef2c.t('key1006566'),
                  path: '/handoverSheetManageDetails',
                  menuKey: 'yms_handoverSheetManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006567'),
      icon: '&#xe668;',
      type: 'supplierList',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006568'),
          path: '/supplierList',
          menuKey: 'yms_supplierList'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006569'),
          path: '/supplierAuditList',
          menuKey: 'yms_supplierAuditList'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006570'),
          path: '/supplierLevel',
          menuKey: 'yms_supplierLevel'
        }
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006571'),
      icon: '&#xe699;',
      type: 'distributorList',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006572'),
          path: '/customerList',
          menuKey: 'yms_customerList',
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002323'),
          path: '/customerLabel',
          menuKey: 'yms_customerLabel',
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002322'),
          path: '/customerGroups',
          menuKey: 'yms_customerGroups',
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002324'),
          path: '/customerGrade',
          menuKey: 'yms_customerGrade'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006573'),
          path: '/tuokeLink',
          menuKey: 'yms_tuokeLink'
        }
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1001039'),
      icon: '&#xe668;',
      type: 'supplierBillManage',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006574'),
          path: '/supplierBillManage',
          menuKey: 'yms_supplierBillManage',
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006575'),
              path: '/supplierBillDetails',
              menuKey: 'yms_supplierBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002086'),
          path: '/customerBill',
          menuKey: 'yms_customerBill',
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006576'),
              path: '/customerBillDetails',
              menuKey: 'yms_customerBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002087'),
          path: '/logisticsBill',
          menuKey: 'yms_logisticsBill',
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006577'),
              path: '/logisticsBillDetails',
              menuKey: 'yms_logisticsBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002088'),
          path: '/warehouseBill',
          menuKey: 'yms_warehouseBill',
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006578'),
              path: '/warehouseBillDetails',
              menuKey: 'yms_warehouseBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002716'),
          path: '/financialJournal',
          menuKey: 'yms_financialJournal'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002081'),
          path: '/rechargeRecord',
          menuKey: 'yms_rechargeRecord'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006579'),
          path: '/passwordManage',
          menuKey: 'yms_passwordManage'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002080'),
          path: '/subsidyReview',
          menuKey: 'yms_subsidyReview'
        }
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006580'),
      icon: '&#xe68e;',
      type: 'logisticsManage',
      children: [
        {
          name: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1002887')}`,
          path: '/ymsLogistics',
          menuKey: 'yms_ymsLogistics'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006581'),
          path: '/basicLogistics',
          menuKey: 'yms_basicLogistics'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006582'),
          path: '/smartLogisticsRule',
          menuKey: 'yms_smartLogisticsRule'
        },
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1001054'),
      icon: '&#xe68f;',
      type: 'warehouseManage',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006583'),
          path: '/warehouseManage',
          menuKey: 'yms_warehouseManage'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006584'),
          path: '/reservoirManage',
          menuKey: 'yms_reservoirManage'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006585'),
          path: '/locationManage',
          menuKey: 'yms_locationManage'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006586'),
          path: '/messageNotification',
          menuKey: 'yms_messageNotification'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1001553'),
          path: '/exchangeRateConfig',
          menuKey: 'yms_exchangeRateConfig'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006587'),
          path: '/containerManage',
          menuKey: 'yms_containerManage'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006588'),
          path: '/containerSettings',
          menuKey: 'yms_containerSettings'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006589'),
          path: '/addressLibraryManage',
          menuKey: 'yms_addressLibraryManage'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006590'),
          path: '/processConfiguration',
          menuKey: 'yms_processConfiguration'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006591'),
          path: '/iossTaxRateSetting',
          menuKey: 'yms_iossTaxRateSetting'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1000917'),
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006592'),
              path: '/declarationRules',
              menuKey: 'yms_declarationRules'
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1001038'),
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006593'),
              path: '/automaticDistributionRules',
              menuKey: 'yms_automaticDistributionRules'
            }
          ]
        }
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006594'),
      icon: '&#xe68b;',
      type: 'helpCenter',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1000036'),
          path: '/helpCenter',
          menuKey: 'yms_helpCenter',
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006595'),
          path: '/questionnaireInvestigation',
          menuKey: 'yms_questionnaireInvestigation',
          children: [
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1002021'),
              path: '/questionnaireStatistics',
              menuKey: 'yms_questionnaireStatistics',
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1002115'),
              path: '/addQuestionnaire',
              menuKey: 'yms_addQuestionnaire',
              menuHide: true // 菜单不展示
            },
            {
              name: alias2f085dd487c1465383484787b29fef2c.t('key1006596'),
              path: '/editQuestionnaire',
              menuKey: 'yms_editQuestionnaire',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006597'),
          path: '/operatingInstructions',
          menuKey: 'yms_operatingInstructions',
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1000801'),
          id: 'toSystemAnnouncement',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'yms_systemAnnouncement',
        },
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006598'),
      icon: '&#xe691;',
      type: 'dataCenter',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002078'),
          path: '/deliveryDetails',
          menuKey: 'yms_deliveryDetails',
        }
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006599'),
      icon: '&#xe66a;',
      type: 'activityManage',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006600'),
          path: '/siteManage',
          menuKey: 'yms_siteManage',
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006601'),
          path: '/activityManage',
          menuKey: 'yms_activityManage',
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006602'),
          path: '/editActivity',
          menuKey: 'yms_editActivity',
          menuHide: true // 菜单不展示
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006603'),
          path: '/advertisingManage',
          menuKey: 'yms_advertisingManage',
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1003900'),
          path: '/rotationChart',
          menuKey: 'yms_rotationChart',
          menuHide: true // 菜单不展示
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1003867'),
          path: '/vajraDistrict',
          menuKey: 'yms_vajraDistrict',
          menuHide: true // 菜单不展示
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1003901'),
          path: '/topAdvertisingSpace',
          menuKey: 'yms_topAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1003902'),
          path: '/sideAdvertisingSpace',
          menuKey: 'yms_sideAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1003903'),
          path: '/infoAdvertisingSpace',
          menuKey: 'yms_infoAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1002889'),
          path: '/subsidyRules',
          menuKey: 'yms_subsidyRules',
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006604'),
          path: '/distributorFeeAllocation',
          menuKey: 'yms_distributorFeeAllocation',
        },
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1000285'),
      path: '/messageCenter',
      icon: '&#xe8be;',
      menuKey: 'yms_messageCenter',
      jurisdictionHide: true, // 不需要权限判断
      type: 'messageCenter',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006605'),
          path: '/messageMailboxes',
          menuKey: 'yms_messageMailboxes',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006606'),
      icon: '&#xe693;',
      path: '/employeeList',
      menuKey: 'yms_employeeList',
      type: 'employeeList',
    },
    {
      name: alias2f085dd487c1465383484787b29fef2c.t('key1006607'),
      icon: '&#xe664;',
      type: 'importTask',
      children: [
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006608'),
          path: '/importTask',
          menuKey: 'yms_importTask'
        },
        {
          name: alias2f085dd487c1465383484787b29fef2c.t('key1006609'),
          path: '/exportTask',
          menuKey: 'yms_exportTask'
        }
      ]
    }
  ]
};
